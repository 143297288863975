import React, { Component } from "react";
import Fade from "react-reveal";

class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;
    const resumeDownload = this.props.data.resumedownload;

    return (
      <section id="about">
        <Fade duration={1000}>
          <div className="row">
            <div className="three columns">
              <img
                className="profile-pic"
                src={profilepic}
                alt="Nordic Giant Profile Pic"
              />
            </div>
            <div className="nine columns main-col">
              <h2>Інформація</h2>

              <p>{bio} </p>
              <div className="row">
                {/* <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
                    <span>{name}</span>
                    <br />
                    <span>
                      {street}
                      <br />
                      {city} {state}, {zip}
                    </span>
                    <br />
                    <span>{phone}</span>
                    <br />
                    <span>{email}</span>
                  </p>
                </div> */}
                <div className="columns download">
                  {/* <p>
                    <a href={resumeDownload} className="button">
                      <i className="fa fa-download"></i>Download Resume
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
            <div class="container">
              <div class="cell">
                <img src="/images/icons/01.svg" alt="Image 1" />
                <h1>Title 1</h1>
                <p>Description for item 1.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/02.svg" alt="Image 2" />
                <h1>Title 2</h1>
                <p>Description for item 2.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/03.svg" alt="Image 3" />
                <h1>Title 3</h1>
                <p>Description for item 3.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/04.svg" alt="Image 4" />
                <h1>Title 4</h1>
                <p>Description for item 4.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/05.svg" alt="Image 5" />
                <h1>Title 5</h1>
                <p>Description for item 5.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/06.svg" alt="Image 6" />
                <h1>Title 6</h1>
                <p>Description for item 6.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/07.svg" alt="Image 7" />
                <h1>Title 6</h1>
                <p>Description for item 6.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/08.svg" alt="Image 8" />
                <h1>Title 6</h1>
                <p>Description for item 6.</p>
              </div>
              <div class="cell">
                <img src="/images/icons/09.svg" alt="Image 9" />
                <h1>Title 6</h1>
                <p>Description for item 6.</p>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default About;
