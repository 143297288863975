import React, { useState } from "react";
import { Fade, Slide } from "react-reveal";

const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      phone,
      checkbox1,
      checkbox2,
      message,
    };
    let postData = JSON.stringify(data);
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    const check = data.checkbox1 ? "yes" : "no";
    const check2 = data.checkbox2 ? "yes" : "no";
    formData.append("checkbox1", check);
    formData.append("checkbox2", check2);
    formData.append("message", data.message);
    console.log(formData);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzYML93BNpMsTyq8eYfJ0AtQxb6n5vuUVBt-c_zMaBagKNCY5ghKT7ppEA08dzK9Nmq/exec",
        {
          method: "POST",
          // headers: {
          //   "Content-Type": "text/plain;charset=utf-8",
          // },
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        setMessage("Form submitted successfully!");
        console.log(result);
        console.log(data);
        console.log(formData);
        console.log(JSON.stringify(data));
      } else {
        setMessage("Failed to submit form.");
      }
    } catch (error) {
      setMessage("An error occurred: " + error.message);
    }
  };
  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Зв'яжіться з нами</span>
            </h1>
          </div>

          {/* <div className="ten columns">
              <p className="lead">{message}</p>
            </div> */}
        </div>
      </Fade>

      <div className="row">
        <Slide left duration={1000}>
          <div className="eight columns">
            <h1>Замовити або Зв'язатись</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <label>
                  Ім'я:
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Телефон:
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </label>
              </div>
              <div>
                <label>
                  Checkbox 1:
                  <input
                    type="checkbox"
                    checked={checkbox1}
                    onChange={(e) => setCheckbox1(e.target.checked)}
                  />
                </label>
              </div>
              <div>
                <label>
                  Checkbox 2:
                  <input
                    type="checkbox"
                    checked={checkbox2}
                    onChange={(e) => setCheckbox2(e.target.checked)}
                  />
                </label>
              </div>
              <div>
                {" "}
                <label>
                  Повідомлення:{" "}
                  <textarea
                    cols="50"
                    rows="3"
                    id="contactMessage"
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </label>
              </div>
              <button type="submit">Submit</button>
            </form>

            {/* <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div> */}
          </div>
        </Slide>

        <Slide right duration={1000}>
          <div className="four columns footer-widgets">
            <div className="widget widget_contact">
              <h4>Умови надання плат:</h4>
              <p className="address"></p>
            </div>

            <div className="widget widget_tweets">
              <h4 className="widget-title">Наші Контакти</h4>
              <ul id="twitter">
                <li>
                  <span>Пошта? Телега?</span>
                </li>
                <li>
                  <span>Ще якийсь дуже важливий текст</span>
                </li>
              </ul>
            </div>
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default Contact;
